import axios from 'axios';

class StatisticsService {

    getTotal() {
        return axios.get(process.env.REACT_APP_API_URL + '/v1/statistics?type=total');
    }
    getLastWeek() {
        return axios.get(process.env.REACT_APP_API_URL + '/v1/statistics?type=lastweek');
    }
    getLastMonth() {
        return axios.get(process.env.REACT_APP_API_URL + '/v1/statistics?type=lastmonth');
    }
    getFoundCount() {
        return axios.get(process.env.REACT_APP_API_URL + '/v1/statistics?type=found_count');
    }
    getComments() {
        return axios.get(process.env.REACT_APP_API_URL + '/v1/statistics?type=comments');
    }

}

export default new StatisticsService();