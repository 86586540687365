import { React, useEffect, useState } from 'react';
import './App.css';
import { Container } from 'react-bootstrap';


function About() {
    return (
        <Container>
            <h2>Ivo – In Optimus Vitae (Das Beste Leben)</h2>
            <p>
                Herzlich willkommen bei Ivo! Wir stehen für mehr als nur ein kühles Bier – unsere Marke verkörpert das beste Leben. Der Name Ivo leitet sich aus dem lateinischen „In Optimus Vitae“ ab, was übersetzt „das Beste Leben“ bedeutet. Für uns geht es nicht nur um das Produkt, sondern um den Lifestyle, der damit verbunden ist: unvergessliche Momente, gesellige Runden und die Kunst, das Leben in vollen Zügen zu genießen.
            </p>
            <h4>Lecker Bierchen – Der Geschmack des Lebens</h4>
            <p>Unter dem Motto „Lecker Bierchen“ bringen wir genau das auf den Punkt. Ivo steht für einzigartigen Biergenuss, der nicht nur den Durst löscht, sondern auch die besonderen Augenblicke im Leben begleitet. Ob beim Grillen mit Freunden, einem entspannten Feierabend oder dem großen Fest – mit Ivo erlebst du das Beste vom Leben.
            </p>
            <h4>Sei dabei – Lebe das Beste Leben</h4>
            <p>Ivo lädt dich ein, Teil unserer Reise zu werden und das Leben so zu genießen, wie es sein sollte: optimus, besser, voller Geschmack und voller Freude. Prost auf das Beste Leben – <b>Prost auf Ivo!</b></p>
            <div style={{width: "100%", textAlign: "center"}}><img src="ivo.gif"/></div>
        </Container>
    );
}

export default About;