import { React, useEffect, useState } from 'react';
import { Container, Tab, Table, Tabs } from "react-bootstrap";
import StatisticsService from "./services/statistics.service";

import './App.css';

import "font-awesome/css/font-awesome.min.css";


function Statistics() {
    const [rowsTotal, setRowsTotal] = useState([])
    const [rowsLastWeek, setRowsLastWeek] = useState([])
    const [rowsLastMonth, setRowsLastMonth] = useState([])
    const [rowsComments, setRowsComments] = useState([])
    const [rowsFoundCount, setRowsFoundCount] = useState([])

    useEffect(() => {
        StatisticsService.getTotal().then(
            response => {
                console.log(response.data)
                setRowsTotal(response.data)
            },
            error => { alert(error) })
        StatisticsService.getLastWeek().then(
            response => {
                console.log(response.data)
                setRowsLastWeek(response.data)
            },
            error => {
                alert(error)
            })
        StatisticsService.getLastMonth().then(
            response => {
                console.log(response.data)
                setRowsLastMonth(response.data)
            }, error => {
                alert(error)
            })
        StatisticsService.getComments().then(
            response => {
                console.log(response.data)
                setRowsComments(response.data)
            }, error => {
                alert(error)
            })
        StatisticsService.getFoundCount().then(
            response => {
                console.log(response.data)
                setRowsFoundCount(response.data)
            }, error => {
                alert(error)
            })
    }, []);

    return (
        <>
            <Tabs defaultActiveKey={1} justify style={{ paddingTop: "10px" }}>
                <Tab eventKey={1} title="Gesamt">
                    <Container style={{ paddingTop: "20px" }}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Kleber</th>
                                    <th>Anzahl</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowsTotal ? rowsTotal.map((row) => (<tr key={row.Name}><td>{row.Name}</td><td>{row.Total}</td></tr>)) : <tr><td>keine Daten vorhanden</td></tr>}
                            </tbody>
                        </Table>
                    </Container>
                </Tab>
                <Tab eventKey={2} title="Letzte Woche">
                    <Container style={{ paddingTop: "20px" }}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Kleber</th>
                                    <th>Anzahl</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowsLastWeek ? rowsLastWeek.map((row) => (<tr key={row.Name}><td>{row.Name}</td><td>{row.Total}</td></tr>)) : <tr><td>keine Daten vorhanden</td></tr>}
                            </tbody>
                        </Table>
                    </Container>
                </Tab>
                <Tab eventKey={3} title="Letzter Monat">
                    <Container style={{ paddingTop: "20px" }}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Kleber</th>
                                    <th>Anzahl</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowsLastMonth ? rowsLastMonth.map((row) => (<tr key={row.Name}><td>{row.Name}</td><td>{row.Total}</td></tr>)) : <tr><td>keine Daten vorhanden</td></tr>}
                            </tbody>
                        </Table>
                    </Container>
                </Tab>
                <Tab eventKey={4} title="Kommentare">
                    <Container style={{ paddingTop: "20px" }}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Anzahl</th>
                                    <th>Sticker</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowsComments ? rowsComments.map((row) => (
                                    <tr key={row.Id}><td>{row.Counter}</td><td><a href={"/id/" + row.Id}>Link</a></td></tr>
                                )) : <tr><td>keine Daten vorhanden</td></tr>}
                            </tbody>
                        </Table>
                    </Container>
                </Tab>
                <Tab eventKey={5} title="Favoriten">
                    <Container style={{ paddingTop: "20px" }}>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Anzahl</th>
                                    <th>Sticker</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rowsFoundCount ? rowsFoundCount.map((row) => (
                                    <tr key={row.Id}><td>{row.Counter}</td><td><a href={"/id/" + row.Id}>Link</a></td></tr>
                                )) : <tr><td>keine Daten vorhanden</td></tr>}
                            </tbody>
                        </Table>
                    </Container>
                </Tab>
            </Tabs>
        </>
    );
}

export default Statistics;