import axios from 'axios';

class StickersService {

    getStickers() {
        return axios.get(process.env.REACT_APP_API_URL + '/v1/stickers');
    }
    getSticker(id) {
        return axios.get(process.env.REACT_APP_API_URL + '/v1/sticker/' + id);
    }
    deleteSticker(id) {
        return axios.delete(process.env.REACT_APP_API_URL + '/stickers/' + id)
    }
    postSticker(sticker) {
        return axios.post(process.env.REACT_APP_API_URL + '/v1/stickers', sticker)
    }
    postPhoto(stickerId, photo) {
        return axios.post(process.env.REACT_APP_API_URL + '/v1/sticker/' + stickerId + "/photos", photo)
    }
    postComment(stickerId, comment) {
        return axios.post(process.env.REACT_APP_API_URL + '/v1/sticker/' + stickerId + "/comments", comment)
    }
    increaseFoundCount(stickerId) {
        return axios.post(process.env.REACT_APP_API_URL + '/v1/sticker/' + stickerId + "/increaseFoundCount")
    }
    putGlueName(stickerId, glueName) {
        let data = {
            name: glueName,
            person_type: "glue"
        }
        return axios.patch(process.env.REACT_APP_API_URL + '/v1/sticker/' + stickerId, data)
    }

}

export default new StickersService();