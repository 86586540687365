import { React, useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, CircleMarker } from 'react-leaflet'
import { Button, Modal } from "react-bootstrap";

import './App.css';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster'
import StickerDetailModal from "./StickerDetailModal.js";
import NewStickerModal from "./NewStickerModal.js";
import StickersService from "./services/stickers.service";

import "leaflet-easybutton/src/easy-button.js";
import "leaflet-easybutton/src/easy-button.css";
import "font-awesome/css/font-awesome.min.css";

import 'leaflet-geosearch/dist/geosearch.css';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function Map() {
  const mapRef = useRef(null);
  const [position, setPosition] = useState([52.3759, 9.7320]);
  const [markers, setMarkers] = useState([])
  const origMarkersRef = useRef([])
  const [show, setShow] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [showNewStickerModal, setShowNewStickerModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [locationPosition, setLocationPosition] = useState([0, 0])
  const positionRef = useRef(null)
  const [spinnerText, setSpinnerText] = useState("Sticker werden geladen")
  const [zoom, setZoom] = useState(13);
  const [linkAlreadyOpenend, setLinkAlreadyOpenend] = useState(false)
  const itemRef = useRef([])
  const selectedDefaultRef = useRef([])

  let { id } = useParams();

  const loadStickers = () => {
    setSpinnerText("Sticker werden geladen")
    setSpinner(true)
    StickersService.getStickers().then(
      response => {
        const markers = [];
        response.data.forEach((element) => {
          var marker = {
            id: element.ID,
            position: [element.latitude, element.longitude],
            data: {
              name: element.name,
              personType: element.person_type,
              glueName: element.glue_name,
              findName: element.find_name,
              foundCount: element.found_count,
              photoIds: element.photo_ids,
              comments: element.comments,
              stickerId: element.ID,
            }
          };
          markers.push(marker);
          let itemFound = false
          for (let item of itemRef.current) {
            if (item.value == element.glue_name || element.glue_name == "") {
              itemFound = true
              break
            }
          }
          if (!itemFound) {
            itemRef.current.push({label: element.glue_name, value: element.glue_name})
            selectedDefaultRef.current.push(element.glue_name)
          }

          if (typeof id !== 'undefined' && element.ID == id && !linkAlreadyOpenend) {
            console.log("FOUND sticker id")
            setPosition([element.latitude, element.longitude]);
            setZoom(17);
            mapRef.current.setView([element.latitude, element.longitude])
            setModalData(markers.find(x => x.id == id).data)
            handleOpen();
            setLinkAlreadyOpenend(true)
          }
          setMarkers(markers)
          origMarkersRef.current = markers
          setSpinner(false)
        })
      },
      error => {
        console.log("StickersService Error getPublicContent", error.response);
        setSpinner(false)
      }
    );
  }


  useEffect(() => {
    loadStickers();
  }, []);

  // If we close the new sitcker modal, we close the opened popup and reload the stickers
  useEffect(() => {
    console.log("showNewStickerModal", showNewStickerModal);
    if (showNewStickerModal || !positionRef.current) {
      return
    }
    positionRef.current.closePopup()
    setLocationPosition([0, 0])
    loadStickers()

  }, [showNewStickerModal])

  const iconIvo = new L.Icon({
    iconUrl: require('./ivo.gif'),
    iconRetinaUrl: require('./ivo.gif'),
    iconSize: new L.Point(32, 20),
  });


  useEffect(() => {
    if (!mapRef.current) return;

    const easyButton = L.easyButton("fa-map-marker", () => {
      console.log("Position wird gesucht")
      setSpinnerText("Position wird gesucht")
      setSpinner(true)

      mapRef.current.locate().on("locationfound", function (e) {
        console.log("Position wurde gefunden: ", e.latlng)
        setSpinner(false)
        setPosition(e.latlng);
        setLocationPosition(e.latlng)
        positionRef.current.closePopup();
        mapRef.current.setView(e.latlng, 17)
      });
      mapRef.current.on("locationerror", function (e) {
        setSpinner(false)
        alert("Error on getting current position: " + e.message);
      })
    })
    easyButton.addTo(mapRef.current);

    L.Control.Select = L.Control.extend({
      options: {
        position: "topleft",

        iconMain: "≡",
        iconChecked: "◉", // "☑"
        iconUnchecked: "ⵔ", //"❒",
        iconGroupChecked: "▶",
        iconGroupUnchecked: "⊳",

        multi: false,

        items: [], // {value: 'String', 'label': 'String', items?: [items]}
        id: "",
        selectedDefault: false,
        additionalClass: "",

        onOpen: () => { },
        onClose: () => { },
        onGroupOpen: (itemGroup) => { },
        onSelect: (item) => { },
      },

      initialize(options) {
        this.menus = [];
        L.Util.setOptions(this, options);
        const opts = this.options;

        this.options.items.forEach((item) => {
          if (!item.label) {
            item.label = item.value;
          }
        });

        if (opts.multi) {
          opts.selectedDefault =
            opts.selectedDefault instanceof Array ? opts.selectedDefault : [];
        } else {
          opts.selectedDefault =
            opts.selectedDefault ||
            (opts.items instanceof Array && opts.items.length > 0
              ? opts.items[0].value
              : false);
        }

        this.state = {
          selected: opts.selectedDefault, // false || multi ? {value} : [{value}]
          open: false, // false || 'top' || {value}
        };

        // assigning parents to items
        const assignParent = (item) => {
          if (this._isGroup(item)) {
            item.items.map((item2) => {
              item2.parent = item.value;
              assignParent(item2);
            });
          }
        };

        this.options.items.map((item) => {
          item.parent = "top";
          assignParent(item);
        });

        // assigning children to items
        const getChildren = (item) => {
          let children = [];
          if (this._isGroup(item)) {
            item.items.map((item2) => {
              children.push(item2.value);
              children = children.concat(getChildren(item2));
            });
          }
          return children;
        };

        const assignChildrens = (item) => {
          item.children = getChildren(item);

          if (this._isGroup(item)) {
            item.items.map((item2) => {
              assignChildrens(item2);
            });
          }
        };

        this.options.items.map((item) => {
          assignChildrens(item);
        });
      },

      onAdd(map) {
        this.map = map;
        const opts = this.options;

        this.container = L.DomUtil.create(
          "div",
          `leaflet-control leaflet-bar leaflet-control-select ${this.options.additionalClass || ""
          }`
        );
        this.container.setAttribute("id", opts.id);

        const icon = L.DomUtil.create(
          "a",
          "leaflet-control-button ",
          this.container
        );
        icon.innerHTML = opts.iconMain;

        map.on("click", this._hideMenu, this);

        L.DomEvent.on(icon, "click", L.DomEvent.stop);
        L.DomEvent.on(icon, "click", this._iconClicked, this);

        L.DomEvent.disableClickPropagation(this.container);
        L.DomEvent.disableScrollPropagation(this.container);

        this.render();
        return this.container;
      },

      _emit(action, data) {
        const newState = {};

        switch (action) {
          case "ITEM_SELECT":
            if (this.options.multi) {
              newState.selected = this.state.selected.slice();

              if (this.state.selected.includes(data.item.value)) {
                newState.selected = newState.selected.filter(
                  (s) => s !== data.item.value
                );
              } else {
                newState.selected.push(data.item.value);
              }
            } else {
              newState.selected = data.item.value;
            }
            break;

          case "GROUP_OPEN":
            newState.open = data.item.value;
            break;

          case "GROUP_CLOSE":
            newState.open = data.item.parent;
            break;

          case "MENU_OPEN":
            newState.open = "top";
            break;

          case "MENU_CLOSE":
            newState.open = false;
            break;
        }

        this._setState(newState);
        this.render();
      },

      _setState(newState) {
        // events
        if (
          this.options.onSelect &&
          newState.selected &&
          ((this.options.multi &&
            newState.selected.length !== this.state.selected.length) ||
            (!this.options.multi && newState.selected !== this.state.selected))
        ) {
          this.options.onSelect(newState.selected);
        }

        if (
          this.options.onGroupOpen &&
          newState.open &&
          newState.open !== this.state.open
        ) {
          this.options.onGroupOpen(newState.open);
        }

        if (this.options.onOpen && newState.open === "top") {
          this.options.onOpen();
        }

        if (this.options.onClose && !newState.open) {
          this.options.onClose();
        }

        this.state = Object.assign(this.state, newState);
      },

      _isGroup(item) {
        return "items" in item;
      },

      _isSelected(item) {
        const sel = this.state.selected;
        if (sel) {
          if (this._isGroup(item)) {
            if ("children" in item) {
              return this.options.multi
                ? sel.find((s) => item.children.includes(s))
                : item.children.includes(sel);
            } else {
              return false;
            }
          }
          return this.options.multi
            ? sel.indexOf(item.value) > -1
            : sel === item.value;
        } else {
          return false;
        }
      },

      _isOpen(item) {
        const open = this.state.open;
        return open && (open === item.value || item.children.includes(open));
      },

      _hideMenu() {
        this._emit("MENU_CLOSE", {});
      },

      _iconClicked() {
        this._emit("MENU_OPEN", {});
      },

      _itemClicked(item) {
        if (this._isGroup(item)) {
          this.state.open === item.value
            ? this._emit("GROUP_CLOSE", { item })
            : this._emit("GROUP_OPEN", { item });
        } else {
          this._emit("ITEM_SELECT", { item });
        }        
      },

      _renderRadioIcon(selected, contentDiv) {
        const radio = L.DomUtil.create("span", "radio icon", contentDiv);

        radio.innerHTML = selected
          ? this.options.iconChecked
          : this.options.iconUnchecked;
      },

      _renderGroupIcon(selected, contentDiv) {
        const group = L.DomUtil.create("span", "group icon", contentDiv);

        group.innerHTML = selected
          ? this.options.iconGroupChecked
          : this.options.iconGroupUnchecked;
      },

      _renderItem(item, menu) {
        const selected = this._isSelected(item);

        const p = L.DomUtil.create("div", "leaflet-control-select-menu-line", menu);
        const pContent = L.DomUtil.create(
          "div",
          "leaflet-control-select-menu-line-content",
          p
        );
        const textSpan = L.DomUtil.create("span", "text", pContent);

        textSpan.innerHTML = item.label;

        if (this._isGroup(item)) {
          this._renderGroupIcon(selected, pContent);

          // adding classes to groups and opened group
          L.DomUtil.addClass(p, "group");
          this._isOpen(item) && L.DomUtil.addClass(p, "group-opened");

          this._isOpen(item) && this._renderMenu(p, item.items);
        } else {
          this._renderRadioIcon(selected, pContent);
        }

        L.DomEvent.addListener(pContent, "click", (e) => {
          L.DomEvent.stop(e);
          this._itemClicked(item);
        });

        return p;
      },

      _renderMenu(parent, items) {
        const menu = L.DomUtil.create(
          "div",
          "leaflet-control-select-menu leaflet-bar ",
          parent
        );
        this.menus.push(menu);
        items.map((item) => {
          this._renderItem(item, menu);
        });
      },

      _clearMenus() {
        this.menus.map((menu) => menu.remove());
        this.meus = [];
      },

      render() {
        this._clearMenus();
        if (this.state.open) {
          this._renderMenu(this.container, this.options.items);
        }
      },

      /* public methods */
      close() {
        this._hideMenu();
      },

    });

    L.control.select = (options) => new L.Control.Select(options);

    let selectControl = L.control.select({
      iconChecked: "☑",
      iconUnchecked: "❒",
      selectedDefault: selectedDefaultRef.current,
      items: itemRef.current,
      multi: true,
      onSelect: function (newItemValue) {
        let newMarkers = []
        for (let marker of origMarkersRef.current) {
          for (let name of newItemValue) {
          if (marker.data.glueName == name) {
            newMarkers.push(marker)
          }
        }
        }
        setMarkers(newMarkers)
      }
    }).addTo(mapRef.current);

    let _dblClickTimer = null;
    mapRef.current.on("click", function (e) {
      selectControl.close()
      if (_dblClickTimer !== null) {
        return
      }
      _dblClickTimer = setTimeout(() => {
        // On the first click for a marker the map always pan with the marker in the upper right corner.
        // The next clicks this didn't happenend. This workaround remembers the center of the map and
        // recenter the map after some milliseconds to its position.
        const center = mapRef.current.getCenter()
        setLocationPosition(e.latlng)
        positionRef.current.openPopup()
        setTimeout(() => {
          mapRef.current.panTo(center)
        }, 10);
        _dblClickTimer = null;
      }, 200)
    });
    mapRef.current.on("dblclick", function () {
      clearTimeout(_dblClickTimer);
      _dblClickTimer = null;
    })
    mapRef.current.on("movestart", function () {
      selectControl.close()
    });

    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider: provider,
      showMarker: false,
      showPopup: false,
      autoClose: true,
      style: window.innerWidth > 400 ? "bar" : "button",
      searchLabel: "Gebe eine Adresse ein",
    });
    mapRef.current.addControl(searchControl);

  }, [mapRef.current]);

  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);

  const handleOpenNewStickerModal = () => setShowNewStickerModal(true);
  const handleCloseNewStickerModal = () => setShowNewStickerModal(false);

  const addNewSticker = (data) => {
    console.log("addNewSticker", data)
  }

  return (
    <>
      <Modal show={spinner} backdrop="static" keyboard={false} centered={true} size="sm">
        <Modal.Body>
          <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-xl"></FontAwesomeIcon>&nbsp;{spinnerText}
        </Modal.Body>
      </Modal>
      <MapContainer center={position} zoom={zoom} scrollWheelZoom={true} ref={mapRef} >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <MarkerClusterGroup>
          {markers.map(
            (p) => (
              <Marker key={p.id} position={p.position} radius={5} icon={iconIvo} data={p.data} eventHandlers={{
                click: (e) => {
                  setModalData(e.target.options.data)
                  handleOpen();
                },
              }}></Marker>
            )
          )}
        </MarkerClusterGroup>
        <CircleMarker center={locationPosition} radius={5} stroke="#fff" ref={positionRef}>
          <Popup><Button onClick={handleOpenNewStickerModal}>Neuen Sticker anlegen</Button></Popup>
        </CircleMarker>

      </MapContainer>
      <StickerDetailModal show={show} onClose={handleClose} data={modalData} setData={setModalData} />
      <NewStickerModal show={showNewStickerModal} onClose={handleCloseNewStickerModal} data={locationPosition} addNewSticker={addNewSticker} />
    </>
  );
}

export default Map;