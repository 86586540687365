import { React } from 'react';
import {Switch, Route,  BrowserRouter as Router} from "react-router-dom";

import './App.css';
import Map from './Map';
import NavBar from './NavBar';
import Statistics from './Statistics';
import About from './About';

function App() {

  return (
    <>
    <Router>
      <div>
        <NavBar/>
        <div className="">
          <Switch>
            <Route exact path="/" component={Map}/>
            <Route exact path="/id/:id" component={Map}/>
            <Route exact path="/statistics" component={Statistics} />
            <Route exact path="/about" component={About} />
          </Switch>
        </div>
      </div>
    </Router>
    </>
  );
}

export default App;