import { React, useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col, Image } from "react-bootstrap";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import StickersService from "./services/stickers.service";
import { faCamera, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NewStickerModal({ show, onClose, data }) {
  const [base64Picture, setBase64Picture] = useState("/no_picture.png")
  const [hideSave, setHideSave] = useState(false)
  const [newData, setNewData] = useState(
    {
      longitude: "",
      latitude: "",
      person_type: "glue",
      name: "",
      photo: ""
    }
  )

  useEffect(() => {
    if (show) {
      let storageName = localStorage.getItem('name');
      let name = "";
      if (storageName != null) {
        name = storageName;
      }

      setHideSave(false)
      setBase64Picture("/no_picture.png")
      setNewData(
        {
          longitude: String(data.lng),
          latitude: String(data.lat),
          person_type: "glue",
          name: name,
          photo: ""
        })
    }
  }, [show, data.lng, data.lat])

  const takePhoto = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera
    }).catch(err => { });

    if (!image) {
      return
    }
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    //var imageUrl = image.webPath;

    // Can be set to the src of an image now
    //console.log(imageUrl)
    setBase64Picture("data:image/png;base64, " + image.base64String)
    //setPhoto(image.base64String)  
    setNewData({
      ...newData,
      photo: image.base64String
    })
  }

  const takeGallery = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos
    }).catch(err => { });

    if (!image) {
      return
    }
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    //var imageUrl = image.webPath;

    // Can be set to the src of an image now
    //console.log(imageUrl)
    setBase64Picture("data:image/png;base64, " + image.base64String)
    //setPhoto(image.base64String)  
    setNewData({
      ...newData,
      photo: image.base64String
    })
  }

    const handleSubmit = e => {
      e.preventDefault()
      setHideSave(true)
      //newData.latitude = String(data.lat)
      //newData.longitude = String(data.lng)
      console.log("Send data:", JSON.stringify(newData))
      StickersService.postSticker(JSON.stringify(newData))
      .then(() => {
        // We save the name in locale storage, to use it later
        localStorage.setItem("name", newData.name);        
        onClose()
      })
      .catch(function (error) {
        alert("Error on saving:", error)
        onClose()
      })
    }

  return (
    <>
      <Modal show={show} onHide={onClose} size="xl" scrollable={true} onClose={onClose} backdrop="static">
        <Modal.Header closeButton>Neuen Sticker erstellen
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="2">
                Name
              </Form.Label>
              <Col sm="10">
                <Form.Control placeholder="Gib deinen Namen ein" value={newData.name} onInput={(e) => {
                  setNewData({
                    ...newData,
                    name: e.target.value
                  })
                }} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
              <Form.Label column sm="2">
                Gefunden oder Geklebt?
              </Form.Label>
              <Col sm="10">
                <Form.Select value={newData.person_type} onInput={(e) => {
                  setNewData({
                    ...newData,
                    person_type: e.target.value
                  })
                }}>
                  <option value="glue">Kleber</option>
                  <option value="finder">Finder</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Image src={base64Picture} fluid></Image><p></p>
            <Button variant="primary" onClick={takeGallery} style={{marginRight: "10px"}}>
              <FontAwesomeIcon icon={faImage} size="2x"></FontAwesomeIcon>
            </Button>
            <Button variant="primary" onClick={takePhoto} >
              <FontAwesomeIcon icon={faCamera} size="2x"></FontAwesomeIcon>
            </Button>
            <p></p>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <div style={{display: hideSave?'block':'none'}}>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-xl" style={{marginRight: "20px"}}></FontAwesomeIcon>Sticker wird gespeichert
          </div>
          <div style={{display: hideSave?'none':'block'}}>
            <Button variant="primary" disabled={!newData.name} onClick={handleSubmit}><FontAwesomeIcon icon={faSave}></FontAwesomeIcon>&nbsp;Abschicken</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
